<template>
  <div>
    <div class="card">
      <div class="card-header">Statistics</div>
      <div class="card-body">
        <div class="form-group">
          <label class="font-bold">Date Range</label>
          <date-picker
            class="form-control"
            format="MM/DD/Y HH:mm"
            :dateInput="dateInput"
            @date-applied="getStats"
          >
          </date-picker>
          <p class="text-muted text-sm mt-2 mb-4">
            Select date time range above and then click "Apply" button to
            generate the stream summary.
          </p>
        </div>

        <div class="d-flex" v-if="loading">
          <div class="spinner-border text-success m-4" role="status">
            <span class="sr-only">Loading...</span>
          </div>

          <strong class="m-4"
            >Please wait while we prepare your report...</strong
          >
        </div>
        <!-- <a
          v-if="countries.length > 0"
          v-bind:href="`/streams/report-export?id=${streamId}&name=${streamName}&start_date=${startDateReport}&end_date=${endDateReport}`"
          class="btn btn-sm btn-primary btn-block"
        >
          <i class="fas fa-download"></i> Export Individual Viewer Data
        </a>

        <a
          v-if="countries.length > 0"
          v-bind:href="`/streams/report-export-summary?id=${streamId}&name=${streamName}&start_date=${startDateReport}&end_date=${endDateReport}`"
          class="btn btn-sm btn-primary btn-block"
        >
          <i class="fas fa-download"></i> Export Viewer Summary
        </a> -->
        <canvas id="serverChart" width="400" height="100"></canvas>

        <p class="text-muted p-4">
          Note: Bandwidth figures is in megabytes (MB).
        </p>

        <div class="row" v-if="!loading">
          <!-- <div class="col-xl-6 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Connections
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.total_connections : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-success text-white rounded-circle shadow"
                    >
                      <i class="fas fa-link"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- Viewers -->
          <div class="col-xl-6 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Unique Viewers<br />
                      {{
                        stats ? `(${stats.start_date} - ${stats.end_date})` : ""
                      }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.total_viewers : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-primary text-white rounded-circle shadow"
                    >
                      <i class="fas fa-eye"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Traffic In -->
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Inbound
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.bw_in_total : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-danger text-white rounded-circle shadow"
                    >
                      <i class="fas fa-long-arrow-alt-up"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Traffic Out -->
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Outbound
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.bw_out_total : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-danger text-white rounded-circle shadow"
                    >
                      <i class="fas fa-long-arrow-alt-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-6 mt-4">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Peak Viewers<br />
                      {{ stats ? `(${stats.max_viewers_date})` : "" }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.max_viewers : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-primary text-white rounded-circle shadow"
                    >
                      <i class="fas fa-eye"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Total Traffic In -->
          <div class="col-xl-3 col-lg-6 mt-4">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Data In
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.bytes_in_total : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-warning text-white rounded-circle shadow"
                    >
                      <i class="fas fa-hdd"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Total Traffic Out -->
          <div class="col-xl-3 col-lg-6 mt-4">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Data Out
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.bytes_out_total : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-warning text-white rounded-circle shadow"
                    >
                      <i class="fas fa-upload"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <!-- <div class="col-md-4">
        <div class="card">
          <div class="card-header">Viewers Summary</div>
          <div class="card-body">
            <table class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Connections</th>
                  <th>Unique<br />visitors</th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="index" v-for="(date, index) in dates">
                  <td>{{ date.date }}</td>
                  <td>{{ date.connections }}</td>
                  <td>{{ date.unique_visitors }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">Countries</div>
          <div class="card-body">
            <div
              class="card card-stats mb-4 mb-xl-0 m-2"
              v-bind:key="index"
              v-for="(country, index) in countries"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      {{ country.country_name }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      country.count
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon">
                      <i
                        v-bind:class="`flag-icon flag-icon-${country.country_code}`"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card">
          <div class="card-header">Devices</div>
          <div class="card-body">
            <div
              class="card card-stats m-2"
              v-bind:key="index"
              v-for="(device, index) in devices"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      {{ device.device_type }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      device.count
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-info text-white rounded-circle shadow"
                      v-if="device.device_type === `Desktop`"
                    >
                      <i class="fas fa-desktop"></i>
                    </div>
                    <div
                      class="icon icon-shape bg-success text-white rounded-circle shadow"
                      v-if="device.device_type === `Mobile`"
                    >
                      <i class="fas fa-mobile"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-header">Browsers</div>
          <div class="card-body">
            <div
              class="card card-stats m-2"
              v-bind:key="index"
              v-for="(browser, index) in browsers"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      {{ browser.browser }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      browser.count
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bytesPerSecondToHuman, bytesToHuman } from "../bytesToHuman";
import Chart from "chart.js/auto";
import moment from "moment";
export default {
  props: ["streamId", "streamName"],
  data() {
    return {
      stats: null,
      streams: [],
      chartData: [],
      chart: null,
      viewersDataset: [],
      bandwidthDataset: [],
      countries: [],
      browsers: [],
      devices: [],
      dates: [],
      startDateReport: null,
      endDateReport: null,
      dateInput: {
        placeholder: "Click here to select date range...",
        inputClass: "form-control",
      },
      loading: false,
    };
  },
  mounted() {
    this.initializeChart();
  },
  methods: {
    getStats: function (start, end) {
      this.loading = true;
      if (this.chart) {
        this.chart.destroy();
        this.initializeChart();
      }
      const startDate = moment(start).format("Y-MM-DD HH:mm");
      const endDate = moment(end).format("Y-MM-DD HH:mm");
      this.startDateReport = startDate;
      this.endDateReport = endDate;
      axios
        .get(
          `/streams/report-details?id=${this.streamId}&name=${this.streamName}&start_date=${startDate}&end_date=${endDate}`
        )
        .then((res) => {
          console.log(res.data);

          let tempFirstDate = moment(start).format("MMM D, Y");
          let tempLastDate = moment(end).format("MMM D, Y");
          let tempMaxViewers = 0;
          let tempMaxViewersDate = "";

          let stat = {
            bw_in_total: bytesPerSecondToHuman(res.data.bw_in_total),
            bytes_in_total: bytesToHuman(res.data.bytes_in_total),
            bw_out_total: bytesPerSecondToHuman(res.data.bw_out_total),
            bytes_out_total: bytesToHuman(res.data.bytes_out_total),
            total_viewers: res.data.max_viewers,
            total_connections: res.data.total_connections,
            // max_viewers: res.data.max_viewers,
          };

          this.countries = res.data.countries;
          this.devices = res.data.devices;
          this.browsers = res.data.browsers;
          // this.dates = res.data.dates;

          //Enumerate between dates
          let dateLabels = [];
          let startMoment = moment(start).startOf("day");
          let endMoment = moment(end).startOf("day");
          dateLabels.push({
            date: startMoment.clone().toDate(),
            bw_in: 0,
            viewers: 0,
          });
          while (startMoment.add(1, "days").diff(endMoment) < 0) {
            dateLabels.push({
              date: startMoment.clone().toDate(),
              bw_in: 0,
              viewers: 0,
            });
          }
          dateLabels.push({
            date: endMoment.clone().toDate(),
            bw_in: 0,
            viewers: 0,
          });

          const data = this.chart.data;
          // const logs = res.data;
          const logs = res.data.logs;

          logs.forEach((log) => {
            dateLabels.forEach((label) => {
              if (moment(label.date).isSame(moment(log.created_at), "day")) {
                label.bw_in += log.bw_in_total / 1000000;
                label.viewers += log.max_viewers;

                // label.connections += log.total_viewers
                //   ? log.total_viewers / res.data.max_viewers +
                //     log.total_viewers / res.data.total_viewers
                //   : 0;
              }
            });
          });

          //Now present the processed data to ChartJS
          dateLabels.forEach((label) => {
            data.labels.push(moment(label.date).format("MMM D, Y"));
            if (data.datasets.length > 0) {
              data.datasets[0].data.push(label.bw_in ? label.viewers : 0);
              data.datasets[1].data.push(
                label.bw_in ? label.bw_in.toFixed(2) : 0
              );
              if (label.bw_in) {
                if (label.viewers > tempMaxViewers) {
                  tempMaxViewers = label.viewers;
                  tempMaxViewersDate = moment(label.date).format("MMM D, Y");
                }
              }
            }
          });

          // NEW formula: peak viewers now based on highest views per day.
          stat.max_viewers = tempMaxViewers;
          stat.max_viewers_date = tempMaxViewersDate;
          stat.start_date = tempFirstDate;
          stat.end_date = tempLastDate;

          this.stats = stat;

          if (this.chart) {
            this.chart.update();
          }

          this.loading = false;
        });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    addData(chart, label, data) {},
    initializeChart() {
      let ctx = document.getElementById("serverChart");
      this.chart = new Chart(ctx, {
        data: {
          labels: [],
          datasets: [
            {
              type: "line",
              label: "viewers",
              data: [],
              fill: false,
              borderColor: "rgb(91,96,245)",
              // tension: 0.1,
            },
            {
              type: "line",
              label: "bandwidth",
              data: [],
              fill: false,
              borderColor: "rgb(255,51,102)",
              // tension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          interaction: {
            mode: "index",
            intersect: false,
          },
          stacked: true,
        },
      });
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
