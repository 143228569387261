<template>
  <div class="card">
    <div class="card-body">
      <form method="post" v-bind:action="'/users/' + userId">
        <slot>
          <!-- CSRF gets injected into this slot -->
        </slot>
        <div class="form-group mb-4">
          <label for="name">Name:</label>
          <input
            type="text"
            class="form-control"
            name="name"
            autocomplete="off"
            v-model="name"
          />
        </div>
        <div class="form-group mb-4">
          <label for="email">E-mail:</label>
          <input
            type="email"
            class="form-control"
            name="email"
            autocomplete="off"
            v-model="email"
          />
        </div>
        <div class="form-group mb-4">
          <label for="password"
            >Password: (only change if going to update the password)</label
          >
          <input type="password" class="form-control" name="password" />
        </div>
        <div class="form-group mb-4">
          <label for="role">Role:</label>
          <select class="form-control" name="role" v-model="roleName">
            <option
              v-for="role in JSON.parse(roles)"
              :value="role.name"
              :key="role.name"
            >
              {{ role.name }}
            </option>
          </select>
        </div>

        <div class="form-group mb-4">
          <label for="streams">Streams:</label>
          <div
            class="card"
            v-for="(stream, counter) in streams"
            v-bind:key="counter"
          >
            <div class="card-header">
              <div class="row">
                <div class="col">Stream # {{ counter + 1 }}</div>
                <div class="col-auto">
                  <button
                    class="btn btn-pill btn-danger btn-sm"
                    type="button"
                    @click="removeStream(counter)"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="stream_name">Name</label>
                    <input
                      class="form-control"
                      type="text"
                      autocomplete="off"
                      placeholder="Stream Name"
                      name="stream_name[]"
                      v-model="stream.name"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="stream_id">Stream ID</label>
                    <input
                      class="form-control"
                      name="stream_id[]"
                      type="text"
                      autocomplete="off"
                      placeholder="slug-123"
                      v-model="stream.stream_id"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="btn btn-outline-primary mt-4 btn-sm"
            @click="addStream"
          >
            + add stream
          </button>
        </div>

        <button type="submit" class="btn btn-success btn-sm btn-block mt-4">
          Update user
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["roles", "user", "userId"],
  data() {
    const user = JSON.parse(this.user);
    return {
      name: user.name,
      email: user.email,
      password: "",
      password_confirmation: "",
      streams: user.streams
        ? user.streams
        : [
            {
              name: "",
              stream_id: "",
            },
          ],
      roleName: user.role,
    };
  },
  methods: {
    addStream: function () {
      this.streams.push({
        name: "",
        stream_id: "",
      });
    },
    removeStream: function (counter) {
      this.streams.splice(counter, 1);
    },
  },
};
</script>
