require("./bootstrap");
import Vue from "vue";
import DatePicker, {
  CalendarDialog,
} from "vue-time-date-range-picker/dist/vdprDatePicker";

window.Vue = require("vue").default;

Vue.component(
  "dashboard-stats",
  require("./components/DashboardStats.vue").default
);
Vue.component(
  "admin-stream-dashboard-stats",
  require("./components/AdminStreamDashboardStats.vue").default
);
Vue.component("date-picker", DatePicker);
Vue.component("calendar-dialog", CalendarDialog);
Vue.component("server-stats", require("./components/ServerStats.vue").default);
Vue.component("stream-stats", require("./components/StreamStats.vue").default);
Vue.component(
  "stream-report",
  require("./components/StreamReport.vue").default
);
Vue.component("user-create", require("./components/UserCreate.vue").default);
Vue.component("user-edit", require("./components/UserEdit.vue").default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
  el: "#app",
});
