<template>
  <div>
    <div class="card">
      <div class="card-header">Statistics</div>
      <div class="card-body">
        <canvas id="serverChart" width="400" height="100"></canvas>
        <div class="row">
          <!-- Viewers -->
          <div class="col-xl-6 col-lg-12">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Viewers
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.total_viewers : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-primary text-white rounded-circle shadow"
                    >
                      <i class="fas fa-eye"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Traffic In -->
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Inbound
                    </h5>
                    <span class="h2 font-weight-bold mb-0"
                      >{{ stats ? stats.bw_in.toFixed(2) : "-" }} MB/s</span
                    >
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-danger text-white rounded-circle shadow"
                    >
                      <i class="fas fa-long-arrow-alt-up"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Traffic Out -->
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Outbound
                    </h5>
                    <span class="h2 font-weight-bold mb-0"
                      >{{ stats ? stats.bw_out.toFixed(2) : "-" }} MB/s</span
                    >
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-danger text-white rounded-circle shadow"
                    >
                      <i class="fas fa-long-arrow-alt-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Total Traffic In -->
          <div class="col-xl-3 col-lg-6 offset-xl-6 mt-4">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Data In
                    </h5>
                    <span class="h2 font-weight-bold mb-0"
                      >{{ stats ? stats.bytes_in.toFixed(2) : "-" }} MB</span
                    >
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-warning text-white rounded-circle shadow"
                    >
                      <i class="fas fa-hdd"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Total Traffic Out -->
          <div class="col-xl-3 col-lg-6 mt-4">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Data Out
                    </h5>
                    <span class="h2 font-weight-bold mb-0"
                      >{{ stats ? stats.bytes_out.toFixed(2) : "-" }} MB</span
                    >
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-warning text-white rounded-circle shadow"
                    >
                      <i class="fas fa-upload"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Countries</div>
          <div class="card-body">
            <div class="row">
              <div
                class="card card-stats mb-4 mb-xl-0 col-md-4 m-2"
                v-bind:key="index"
                v-for="(country, index) in countries"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">
                        {{ country.country_name }}
                      </h5>
                      <span class="h2 font-weight-bold mb-0">{{
                        country.count
                      }}</span>
                    </div>
                    <div class="col-auto">
                      <div class="icon">
                        <i
                          v-bind:class="`flag-icon flag-icon-${country.country_code}`"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">Devices</div>
          <div class="card-body">
            <div
              class="card card-stats m-2"
              v-bind:key="index"
              v-for="(device, index) in devices"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      {{ device.device_type }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      device.count
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-info text-white rounded-circle shadow"
                      v-if="device.device_type === `Desktop`"
                    >
                      <i class="fas fa-desktop"></i>
                    </div>
                    <div
                      class="icon icon-shape bg-success text-white rounded-circle shadow"
                      v-if="device.device_type === `Mobile`"
                    >
                      <i class="fas fa-mobile"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-4">
          <div class="card-header">Browsers</div>
          <div class="card-body">
            <div
              class="card card-stats mb-4 mb-xl-0"
              v-bind:key="index"
              v-for="(browser, index) in browsers"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      {{ browser.browser }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      browser.count
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bytesPerSecondToHuman, bytesToHuman } from "../bytesToHuman";
import Chart from "chart.js/auto";
import moment from "moment";
export default {
  props: ["streamId", "streamName"],
  data() {
    return {
      stats: null,
      streams: [],
      countries: [],
      devices: [],
      browsers: [],
      chartData: [],
      chart: null,
      viewersDataset: [],
      bandwidthDataset: [],
    };
  },
  created() {
    this.getStats();
    // this.timer = setInterval(this.getStats, 5000);
  },
  mounted() {
    this.initializeChart();
  },
  methods: {
    getStats: async function () {
      const socket = new WebSocket(
        `${process.env.MIX_APP_ENV === "local" ? "ws" : "wss"}://${
          window.location.hostname
        }${
          process.env.MIX_APP_ENV === "local" ? ":6001" : ""
        }/app/socket/stream-stats?appKey=${process.env.MIX_PUSHER_APP_KEY}`
      );

      let self = this;

      socket.onopen = function (e) {
        socket.send(
          JSON.stringify({ id: self.streamId, name: self.streamName })
        );
      };

      socket.onmessage = function (e) {
        if (e.data) {
          const socketData = JSON.parse(e.data);

          let stat = {
            bw_in: socketData.bw_in,
            bytes_in: socketData.bytes_in,
            bw_out: socketData.bw_out,
            bytes_out: socketData.bytes_out,
            total_viewers: socketData.total_viewers,
            isLoading: true,
          };

          self.countries = socketData.countries;
          self.devices = socketData.devices;
          self.browsers = socketData.browsers;

          // self.viewersDataset.push(socketData.total_viewers);
          // self.bandwidthDataset.push(socketData.bw_in + socketData.bw_out);

          const bandwidthChartStat = bytesPerSecondToHuman(
            socketData.bw_in,
            true
          );

          const data = self.chart.data;
          data.labels.push(moment(socketData.updated_at).format("HH:mm:ss"));
          if (data.datasets.length > 0) {
            data.datasets[0].data.push(
              socketData.bw_in ? socketData.total_viewers : 0
            );
            data.datasets[1].data.push(bandwidthChartStat);
            if (data.labels.length > 9) {
              data.datasets[0].data.shift();
              data.datasets[1].data.shift();
              data.labels.shift();
            }
            self.chart.update();
          }

          self.stats = stat;

          if (self.chart) {
            self.chart.update();
          }

          socket.send(
            JSON.stringify({ id: self.streamId, name: self.streamName })
          );
        }
      };

      // axios
      //   .get(`/streams/stats?id=${this.streamId}&name=${this.streamName}`)
      //   .then((res) => {
      //     let stat = {
      //       bw_in: res.data.bw_in,
      //       bytes_in: res.data.bytes_in,
      //       bw_out: res.data.bw_out,
      //       bytes_out: res.data.bytes_out,
      //       total_viewers: res.data.total_viewers,
      //       isLoading: true,
      //     };

      //     this.countries = res.data.countries;
      //     this.devices = res.data.devices;
      //     this.browsers = res.data.browsers;

      //     // this.viewersDataset.push(res.data.total_viewers);
      //     // this.bandwidthDataset.push(res.data.bw_in + res.data.bw_out);

      //     const data = this.chart.data;
      //     data.labels.push(moment(res.data.updated_at).format("HH:mm:ss"));
      //     if (data.datasets.length > 0) {
      //       data.datasets[0].data.push(res.data.total_viewers);
      //       data.datasets[1].data.push(res.data.bw_in);
      //       if (data.labels.length > 9) {
      //         data.datasets[0].data.shift();
      //         data.datasets[1].data.shift();
      //         data.labels.shift();
      //       }
      //       this.chart.update();
      //     }

      //     this.stats = stat;

      //     if (this.chart) {
      //       this.chart.update();
      //     }
      //   });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    addData(chart, label, data) {},
    initializeChart() {
      let ctx = document.getElementById("serverChart");
      this.chart = new Chart(ctx, {
        data: {
          labels: [],
          datasets: [
            {
              type: "line",
              label: "viewers",
              data: [],
              fill: false,
              borderColor: "rgb(91,96,245)",
              tension: 0.1,
            },
            {
              type: "line",
              label: "bandwidth",
              data: [],
              fill: false,
              borderColor: "rgb(255,51,102)",
              tension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          interaction: {
            mode: "index",
            intersect: false,
          },
          stacked: true,
        },
      });
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
