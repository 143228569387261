<template>
  <div class="row">
    <div class="col-md-12" v-if="isLoading">
      <div class="card mb-4">
        <div class="card-body">
          <ContentLoader
            :speed="2"
            :width="600"
            :height="50"
            viewBox="0 0 900 100"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="30" y="35" rx="3" ry="3" width="62" height="7" />
            <circle cx="15" cy="40" r="11" />
            <rect x="5" y="0" rx="8" ry="8" width="210" height="20" />
            <circle cx="449" cy="20" r="20" />
            <rect x="355" y="3" rx="3" ry="3" width="62" height="7" />
            <rect x="356" y="15" rx="1" ry="3" width="62" height="23" />
            <circle cx="578" cy="20" r="20" />
            <rect x="486" y="15" rx="3" ry="3" width="62" height="23" />
            <rect x="485" y="3" rx="3" ry="3" width="62" height="7" />
          </ContentLoader>
        </div>
      </div>
    </div>
    <div
      class="col-md-12"
      v-for="(server, counter) in servers"
      v-bind:key="counter"
    >
      <div
        class="card card-stats mb-4 cursor-pointer"
        @click="goToDetails(server.id, server.name)"
      >
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h2 class="font-weight-bold mb-0">
                {{ server.name }}
              </h2>
              <div v-if="server.status === 'online'">
                <div
                  class="icon icon-shape icon-xs bg-success text-white rounded-circle shadow"
                ></div>
                <span class="h6 card-title text-uppercase text-muted ml-4"
                  >online</span
                >
              </div>

              <div v-if="server.status === 'streaming'">
                <div
                  class="icon icon-shape icon-xs bg-danger text-white rounded-circle shadow"
                ></div>
                <span class="h6 card-title text-uppercase text-muted ml-4"
                  >streaming - {{ server.uptime }}</span
                >
              </div>

              <div v-if="server.status === 'offline'">
                <div
                  class="icon icon-shape icon-xs bg-secondary text-white rounded-circle shadow"
                ></div>
                <span class="h6 card-title text-uppercase text-muted ml-4"
                  >offline</span
                >
              </div>
            </div>
            <div class="col-auto">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Inbound
                  </h5>
                  <span class="h2 font-weight-bold mb-0"
                    >{{ server ? server.bw_in.toFixed(2) : "-" }} MB/s</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-primary text-white rounded-circle shadow"
                  >
                    <i class="fas fa-long-arrow-alt-up"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    Outbound
                  </h5>
                  <span class="h2 font-weight-bold mb-0"
                    >{{ server ? server.bw_out.toFixed(2) : "-" }} MB/s</span
                  >
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-danger text-white rounded-circle shadow"
                  >
                    <i class="fas fa-long-arrow-alt-down"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  components: { ContentLoader },
  props: ["role", "streams"],
  data() {
    return {
      servers: [],
      isLoading: true,
    };
  },
  created() {
    this.getStats();
  },
  methods: {
    getStats: async function () {
      // await axios.get("/dashboard-stats");
      const socket = new WebSocket(
        `${process.env.MIX_APP_ENV === "local" ? "ws" : "wss"}://${
          window.location.hostname
        }${
          process.env.MIX_APP_ENV === "local" ? ":6001" : ""
        }/app/socket/dashboard-stats?appKey=${process.env.MIX_PUSHER_APP_KEY}`
      );
      let myStreams = [];
      let myRole = "";

      if (this.streams) {
        myStreams = JSON.parse(this.streams);
      }

      if (this.role) {
        myRole = this.role;
      }

      socket.onopen = function (e) {
        socket.send(
          JSON.stringify({
            role: myRole,
            streams: myStreams,
          })
        );
      };

      let self = this;

      socket.onmessage = function (e) {
        if (e.data) {
          const socketData = JSON.parse(e.data);
          self.servers = socketData;
          self.isLoading = false;
          socket.send(
            JSON.stringify({
              role: myRole,
              streams: myStreams,
            })
          );
        }
      };
    },
    goToDetails(id, name) {
      if (this.role === "admin") {
        window.location.href = `/servers/${id}`;
      } else {
        window.location.href = `/streams/details?id=${id}&name=${name}`;
      }
    },
  },
};
</script>
