<template>
  <div>
    <div class="card">
      <div class="card-header">Statistics</div>
      <div class="card-body">
        <canvas id="serverChart" width="400" height="100"></canvas>
        <div class="row">
          <!-- Viewers -->
          <div class="col-xl-6 col-lg-12">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Viewers
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.total_viewers : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-primary text-white rounded-circle shadow"
                    >
                      <i class="fas fa-eye"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Traffic In -->
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Inbound
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.bw_in : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-danger text-white rounded-circle shadow"
                    >
                      <i class="fas fa-long-arrow-alt-up"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Traffic Out -->
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Outbound
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.bw_out : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-danger text-white rounded-circle shadow"
                    >
                      <i class="fas fa-long-arrow-alt-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Total Traffic In -->
          <div class="col-xl-3 col-lg-6 offset-xl-6 mt-4">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Data In
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.bytes_in : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-warning text-white rounded-circle shadow"
                    >
                      <i class="fas fa-hdd"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Total Traffic Out -->
          <div class="col-xl-3 col-lg-6 mt-4">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">
                      Total Data Out
                    </h5>
                    <span class="h2 font-weight-bold mb-0">{{
                      stats ? stats.bytes_out : "-"
                    }}</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-warning text-white rounded-circle shadow"
                    >
                      <i class="fas fa-upload"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bytesPerSecondToHuman, bytesToHuman } from "../bytesToHuman";
import Chart from "chart.js/auto";
import moment from "moment";
export default {
  props: ["serverId"],
  data() {
    return {
      stats: null,
      streams: [],
      chartData: [],
      chart: null,
      viewersDataset: [],
      bandwidthDataset: [],
      isLoading: true,
    };
  },
  created() {
    this.getStats();
    // this.timer = setInterval(this.getStats, 5000);
  },
  mounted() {
    this.initializeChart();
  },
  methods: {
    getStats: async function () {
      const socket = new WebSocket(
        `${process.env.MIX_APP_ENV === "local" ? "ws" : "wss"}://${
          window.location.hostname
        }${
          process.env.MIX_APP_ENV === "local" ? ":6001" : ""
        }/app/socket/server-stats?appKey=${process.env.MIX_PUSHER_APP_KEY}`
      );

      let self = this;

      socket.onopen = function (e) {
        socket.send(JSON.stringify({ id: self.serverId }));
      };

      socket.onmessage = function (e) {
        if (e.data) {
          const socketData = JSON.parse(e.data);

          let stat = {
            bw_in: bytesPerSecondToHuman(socketData.bw_in),
            bytes_in: bytesToHuman(socketData.bytes_in),
            bw_out: bytesPerSecondToHuman(socketData.bw_out),
            bytes_out: bytesToHuman(socketData.bytes_out),
            total_viewers: socketData.total_viewers,
          };

          const bandwidthChartStat = bytesPerSecondToHuman(
            socketData.bw_in,
            true
          );

          self.viewersDataset.push(socketData.total_viewers);
          self.bandwidthDataset.push(socketData.bw_in + socketData.bw_out);

          const data = self.chart.data;
          data.labels.push(moment(socketData.updated_at).format("HH:mm:ss"));
          if (data.datasets.length > 0) {
            data.datasets[0].data.push(socketData.total_viewers);
            data.datasets[1].data.push(bandwidthChartStat);
            if (data.labels.length > 9) {
              data.datasets[0].data.shift();
              data.datasets[1].data.shift();
              data.labels.shift();
            }
            self.chart.update();
          }

          self.stats = stat;

          if (self.chart) {
            self.chart.update();
          }

          socket.send(JSON.stringify({ id: self.serverId }));
        }
      };
      // axios.get(`/servers/${this.serverId}/stats`).then((res) => {
      //   let stat = {
      //     bw_in: bytesPerSecondToHuman(res.data.bw_in),
      //     bytes_in: bytesToHuman(res.data.bytes_in),
      //     bw_out: bytesPerSecondToHuman(res.data.bw_out),
      //     bytes_out: bytesToHuman(res.data.bytes_out),
      //     total_viewers: res.data.total_viewers,
      //   };

      //   // this.viewersDataset.push(res.data.total_viewers);
      //   // this.bandwidthDataset.push(res.data.bw_in + res.data.bw_out);

      //   const data = this.chart.data;
      //   data.labels.push(moment(res.data.updated_at).format("HH:mm:ss"));
      //   if (data.datasets.length > 0) {
      //     data.datasets[0].data.push(res.data.total_viewers);
      //     data.datasets[1].data.push(10000000 / res.data.bw_in);
      //     if (data.labels.length > 9) {
      //       data.datasets[0].data.shift();
      //       data.datasets[1].data.shift();
      //       data.labels.shift();
      //     }
      //     this.chart.update();
      //   }

      //   this.stats = stat;

      //   if (this.chart) {
      //     this.chart.update();
      //   }
      // });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    addData(chart, label, data) {},
    initializeChart() {
      let ctx = document.getElementById("serverChart");
      this.chart = new Chart(ctx, {
        data: {
          labels: [],
          datasets: [
            {
              type: "line",
              label: "viewers",
              data: [],
              fill: false,
              borderColor: "rgb(0, 0, 0)",
              tension: 0.1,
            },
            {
              type: "line",
              label: "bandwidth",
              data: [],
              fill: false,
              borderColor: "rgb(121, 27, 57)",
              tension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          interaction: {
            mode: "index",
            intersect: false,
          },
          stacked: true,
          scales: {
            viewers: {
              type: "linear",
              display: true,
              position: "right",
              ticks: {
                color: "rgb(0, 0, 0)",
              },
            },
            bandwidth: {
              type: "linear",
              display: true,
              position: "left",

              // grid line settings
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
              // ticks: {
              //   color: "rgb(121, 27, 57)",
              //   // Include a dollar sign in the ticks
              //   callback: function (value, index, ticks) {
              //     return value + " MB/s";
              //   },
              // },
            },
          },
        },
      });
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
