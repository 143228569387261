export const bytesPerSecondToHuman = (bytes, hideUnit = false) => {
  let units = ["B/s", "KB/s", "MB/s", "GB/s", "TB/s", "PB/s"];

  let i = 0;

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }
  if (!hideUnit) {
    return Math.floor(bytes) + " " + units[i];
  } else {
    return Math.floor(bytes);
  }
};

export const bytesToHuman = (bytes) => {
  let units = ["B", "KB", "MB", "GB", "TB", "PB"];

  let i = 0;

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return Math.floor(bytes) + " " + units[i];
};
